<!-- <app-loader-v2 *ngIf="showLoader"></app-loader-v2> -->
<div  class="flex flex-col items-center justify-between h-screen font-aller">
   <div class="w-full  flex flex-col items-center justify-between h-full">
    <div class="flex justify-center items-center w-full">
        <app-header-v2 class="w-full flex justify-center items-center" [loading]="4" [showProfileIcon]="false"></app-header-v2>
    </div>
        <div class=" w-full flex items-center justify-center flex-col p-4 text-[#333333] ">
            <!-- Mobile Text -->
            <!-- <div class=" text-center font-bold mb-4  md:hidden">Congratulations!</div> -->
            <!-- <div class=" text-center mb-4  md:hidden"> You just purchased  your  Motor Insurance <br> from Liva.</div> -->
             <div class="text-[1.688rem] font-bold text-center break-all" *ngIf="firstName.length > 16">Thank you,<br>{{firstName}}!</div>
             <div class="text-[1.688rem] font-bold text-center" *ngIf="firstName.length <= 16">Thank you, {{firstName}}!</div>
            <div class="font-light text-center mb-4 text-base  md:hidden">Please submit your documents for the policy issuance.Note that the policy will be issued only once the documents are successfully uploaded. </div>
            <!-- <div class=" text-center mb-4  md:hidden">Please note that the policy will not be issued <br> until the documents are uploaded.</div> -->
            <!-- Web Text -->
            <!-- <div class="hidden md:block text-center font-bold mb-4 text-[1.625rem]">Congratulations!</div> -->
            <!-- <div class="hidden md:block text-center mb-4 text-[1.625rem]">You just purchased your Motor Insurance from Liva.</div> -->
            <div class="hidden md:block text-center mb-4 text-[1.625rem]">Please submit your documents for the policy issuance. <br>
              Note that the policy will be issued only once the documents are successfully uploaded.</div>
            <!-- <div class="hidden md:block text-center mb-4 text-[1.625rem]">Please note that the policy will not be issued until the documents are uploaded.</div> -->

            <button class="w-full  bg-[#FF6B00] text-center rounded-full h-12 text-white   font-bold text-xl md:max-w-[18rem]" (click)="redirectToUploadDocument()">Submit Documents</button>
        </div>
        <div class="w-full">
            <app-need-support></app-need-support>
            <app-footer-v2 class="w-full"></app-footer-v2>
        </div>
    </div>
</div>
