import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loadingSubject$ = new BehaviorSubject<boolean>(false);
  public readonly loadingState 
  
  constructor() {
     this.loadingState = this.loadingSubject$.asObservable();
   }
  show() {
    this.loadingSubject$.next(true);
  }

  hide() {
    this.loadingSubject$.next(false);
  }
}
