import { Component, HostBinding, HostListener, Input } from '@angular/core';

@Component({
  selector: 'app-loader-v2',
  templateUrl: './loader-v2.component.html',
  styleUrls: ['./loader-v2.component.scss']
})
export class LoaderV2Component {

    @HostBinding('style.--animation-duration.s')
    @Input()
    public animationDuration = 5;
  
    @HostListener('window:resize',['$event'])
    onResize(){
      this.updateAnimationDuration()
    }

    constructor() {
      this.updateAnimationDuration()
    }

    private updateAnimationDuration(){
      const screenWidth  = window.innerWidth
      this.animationDuration = screenWidth > 768 ? 5 : 2
    }

}
