<div class=" fixed inset-0 z-20 flex justify-center items-center h-full w-full bg-white ">
   <!-- <div class="loader">
        <div class="bar1"></div>
        <div class="bar2"></div>
        <div class="bar3"></div>
        <div class="bar4"></div>
        <div class="bar5"></div>
        <div class="bar6"></div>
        <div class="bar7"></div>
        <div class="bar8"></div>
        <div class="bar9"></div>
        <div class="bar10"></div>
        <div class="bar11"></div>
        <div class="bar12"></div>
    </div> -->
    <div class="flex flex-col w-full">
        <div class="  relative  w-full h-8  md:h-10 4xl:h-8 xs:h-10 hide-scrollbar overflow-x-hidden hide-scrollbar">
            <div class="w-full border-[1.5px] border-[#FF6900] bottom-0 absolute"></div> 
            <div class=" h-8 car bottom-0 absolute xs:h-10 md:h-10 4xl:h-8">
                <svg xmlns="http://www.w3.org/2000/svg" width="274.106" height="32.725" viewBox="0 0 274.106 32.725">
                    <g id="Group_819" data-name="Group 819" transform="translate(-99.25 -449.766)">
                      <path id="Path_215" data-name="Path 215" d="M4527.438,3115.891h-80.071" transform="translate(-4255.039 -2642.455)" fill="none" stroke="#ff6900" stroke-linecap="round" stroke-width="1.5"/>
                      <path id="Path_213" data-name="Path 213" d="M4622.037,3115.891h-174.67" transform="translate(-4347.367 -2655.071)" fill="none" stroke="#ff6900" stroke-linecap="round" stroke-width="1.5"/>
                      <path id="Path_214" data-name="Path 214" d="M4574.3,3115.891H4447.367" transform="translate(-4301.49 -2648.189)" fill="none" stroke="#ff6900" stroke-linecap="round" stroke-width="1.5"/>
                      <g id="Car" transform="translate(276.025 450.516)">
                        <g id="Group_604" data-name="Group 604" transform="translate(0 0)">
                          <path id="Path_222" data-name="Path 222" d="M126.514,118.7c.748-1.759.4-3.386-.924-6.113s-6.949-6.6-30.082-6.6c-6.421,0-10.6-8.488-21.066-8.488-13.282,0-32.676,7.784-34.435,7.7-10.247-.352-4.882,4.838-6.685,7.608s-2.463,6.861-.572,8.8c.748.792,4.926,1.5,9.763,2.111a8.045,8.045,0,1,1,14.205,1.275c1.627.088,2.815.176,3.3.176H107.3a8.044,8.044,0,1,1,14.689-4.53,7.816,7.816,0,0,1-1.231,4.266c1.847,0,7.432-.792,7.432-1.759C128.405,120.105,125.767,120.457,126.514,118.7Z" transform="translate(-31.614 -97.5)" fill="none" stroke="#ff6900" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                          <ellipse id="Ellipse_121" data-name="Ellipse 121" cx="8.048" cy="8.048" rx="8.048" ry="8.048" transform="translate(10.373 15.129)" fill="none" stroke="#ff6900" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                          <ellipse id="Ellipse_122" data-name="Ellipse 122" cx="8.048" cy="8.048" rx="8.048" ry="8.048" transform="translate(74.45 15.129)" fill="none" stroke="#ff6900" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        </g>
                        <path id="Path_223" data-name="Path 223" d="M107.853,109.405H67.7S84.016,103.6,93.735,103.6a19.38,19.38,0,0,1,14.117,5.805" transform="translate(-51.83 -100.917)" fill="none" stroke="#ff6900" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        <path id="Path_224" data-name="Path 224" d="M35.6,126.291s6.245,1.1,6.245-2.111c0-3.254-6.069-2.639-6.069-2.639" transform="translate(-33.847 -110.942)" fill="none" stroke="#ff6900" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        <path id="Path_225" data-name="Path 225" d="M240.563,128.1a2,2,0,0,0-1.363,1.891c0,1.891,2.067,2.463,3.782,2.639" transform="translate(-147.906 -114.642)" fill="none" stroke="#ff6900" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        <ellipse id="Ellipse_123" data-name="Ellipse 123" cx="3.958" cy="3.958" rx="3.958" ry="3.958" transform="translate(14.463 19.219)" fill="none" stroke="#ff6900" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        <ellipse id="Ellipse_124" data-name="Ellipse 124" cx="3.958" cy="3.958" rx="3.958" ry="3.958" transform="translate(78.54 19.219)" fill="none" stroke="#ff6900" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        <line id="Line_49" data-name="Line 49" x2="4.134" transform="translate(41.334 13.018)" fill="none" stroke="#ff6900" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        <line id="Line_50" data-name="Line 50" y1="4.75" transform="translate(35.265 3.738)" fill="none" stroke="#ff6900" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                      </g>
                    </g>
                  </svg>
            </div>
        </div>
        <!-- <div class="mt-[4.75rem] text-center">
            <p class="text-base font-light leading-5 ">Hold tight, your quote is on the way!</p>
        </div> -->
    </div>
</div>


