import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { CUSTOMERUUVID } from './shared/session-variables';
import { SessionService } from './shared/services/session-service/session.service';
import { QuoteSummaryService } from './shared/services/quote-summary/quote-summary.service';
import axios from 'axios';
import { API_ENDPOINTS } from './shared/api-endpoints';
import { distinctUntilChanged} from 'rxjs';
import { LoaderService } from './shared/services/loader/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  // activeRoute: any;
  customerUuvid: any;
  uuid: string;
  validateUuvid: any
  isValidUUID: any
  loading:boolean = false;
  private timeOutDuration: number = 2000
  // sessionStorage: any;

      // @HostListener('window:resize',['$event'])
      // onResize(){
      //   this.updateAnimationDuration()
      // }
 
      // constructor() {}
      constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private activeRoute: ActivatedRoute, private sessionStorage: SessionService,
    private quoteSumaryService: QuoteSummaryService,
    private loaderService: LoaderService
  ) {
    console.log(`Health check times 17-11-2024--->${environment.deploymentTime}`)
    // this.updateAnimationDuration()
    
  }
    private updateAnimationDuration(){
      const screenWidth  = window.innerWidth
      this.timeOutDuration = screenWidth > 768 ? 5000 : 2000
    }

  ngAfterViewInit(): void {
    // To fix white screen issue
    setTimeout(()=>{
      document.getElementById('loading').style['display'] = 'none';
    },2000)
  }

  async ngOnInit(): Promise<void> {
   this.loaderService.loadingState.pipe(distinctUntilChanged()).subscribe((loading)=>{
      this.loading = loading;
   })
    try {
      // Extract the UUID from the URL path or query parameter
      const urlParams = new URLSearchParams(window.location.search); // Use search instead of href for query parameters
      const customerUuvid = urlParams.get('customerUUvid'); // Get 'customerUUvid' parameter value
      const currentPath = window.location.pathname;

      // Define regex to extract the last segment of the URL path
      const uuidRegex: RegExp = /[^\/]+$/;

      // Determine the UUID to use
      this.uuid = currentPath === '/verify-order' ? customerUuvid : currentPath.match(uuidRegex)?.[0] || null;
      this.sessionStorage.set(CUSTOMERUUVID, this.uuid);

      // Validate the UUID if it exists
      // if (this.uuid) {
      //   const axiosInstance = axios.create({
      //     baseURL: environment.apiUrl,
      //   });
      //   this.isValidUUID = await axiosInstance.get(`${API_ENDPOINTS.createQuote}/${API_ENDPOINTS.validateUUvid}?customerUuvid=${this.uuid}`)
      //   this.isValidUUID = this.isValidUUID['data']['response'][0]['customerUuvidCount']
      //   console.log(this.isValidUUID);

      //   if (this.isValidUUID == 1) {
      //     this.sessionStorage.set(CUSTOMERUUVID, this.uuid);

      //   } else {
      //     this.router.navigateByUrl('/404');
      //   }


      // } else {
      //   console.error('UUID not found in URL');
      //   // this.router.navigateByUrl('/404');
      // }
    } catch (error) {
      console.error('Error during initialization:', error);
      // this.router.navigateByUrl('/404');
    }
  }

  async validateUUID(uuid: string): Promise<any> {
    // console.log(uuid, '-----------uuvid');

    const axiosInstance = axios.create({
      baseURL: environment.apiUrl,
    });

    try {
      // Make API call to validate the UUID
      const apiResponse = await axiosInstance.get(`${API_ENDPOINTS.createQuote}/${API_ENDPOINTS.validateUUvid}?customerUuvid=${uuid}`);
      // console.log(apiResponse, '-----------------api response ---------------------');

      return apiResponse['data']['response'][0]['customerUuvidCount']; // Return true if response status is 200
    } catch (error) {
      console.error('Error validating UUID:', error);
      return false; // Return false in case of error
    }
  }





  title = 'salik-poc-ui';

}